<script setup lang="ts">
import { CmsElementImageGallery } from "@shopware-pwa/composables-next";
import { SwiperOptions } from "swiper";

const props = withDefaults(
  defineProps<{
    content: CmsElementImageGallery;
  }>(),
  {}
);

const viewport = useViewport();
const mediaGallery = computed(() => props.content.data?.sliderItems.sort((a, b) => a.position - b.position) ?? []);

const paginationConfig: SwiperOptions["pagination"] = {
  enabled: true,
  clickable: true,
};

const zoomSwiper = ref(null);
const zoomOpen = ref(false);

const setZoomSwiper = (swiper: Swiper) => {
  zoomSwiper.value = swiper;
};

const toggleZoom = (e: Event, imageNumber: number | null) => {
  e.preventDefault();
  zoomOpen.value = !zoomOpen.value;

  if (zoomSwiper.value && imageNumber !== undefined) {
    zoomSwiper.value.slideTo(imageNumber, 0);
  }
};
</script>

<template>
  <template v-if="viewport.isLessThan('sm')">
    <nuxt-error-boundary @error="() => {}">
      <Swiper
        class="aspect-2/3 w-full"
        :modules="[SwiperPagination]"
        :loop="true"
        :slides-per-view="1"
        :space-between="5"
        :pagination="paginationConfig"
      >
        <SwiperSlide v-for="image of mediaGallery" :key="image.media.url">
          <SharedProxyImg
            v-if="image?.media?.url"
            :src="image.media.url"
            :width="650"
            :height="975"
            :modifiers="{
                width: 650,
                height: 975,
              }"
          />
        </SwiperSlide>
      </Swiper>
    </nuxt-error-boundary>
  </template>
  <div v-else class="@container/imagegallery">
    <div class="@2xl/imagegallery:grid-cols-2 @8xl/imagegallery:grid-cols-3 grid grid-cols-1 grid-rows-[masonry] gap-3">
      <div
        v-for="(image, index) of mediaGallery"
        class="aspect-2/3 bg-maas-background-grey-light cursor-zoom-in"
        @click="(e) => toggleZoom(e, index)"
      >
        <SharedProxyImg
          v-if="image?.media?.url"
          :key="image.media.url"
          :src="image.media.url"
          :width="650"
          :height="975"
          :modifiers="{
              width: 650,
              height: 975,
            }"
          :data-index="index"
          :loading="index < 2 ? 'eager' : 'lazy'"
        />
      </div>
    </div>
  </div>

  <Teleport to="body">
    <div
      class="bg-maas-background-default z-100 cursor-close fixed inset-0 h-full w-full"
      :class="{
        hidden: zoomOpen === false,
      }"
      @click="(e) => toggleZoom(e)"
    >
      <div class="z-101 absolute right-0 top-0 flex h-20 w-20 cursor-auto items-center justify-center">
        <a
          href="#"
          class="bg-maas-background-grey-medium text-small bottom-0 block flex h-8 w-8 cursor-pointer items-center justify-center rounded-full"
          >✕</a
        >
      </div>
      <div class="absolute inset-0 h-full w-full touch-auto">
        <nuxt-error-boundary @error="() => {}">
          <Swiper
            class="max-h-screen w-full"
            :modules="[SwiperFreeMode, SwiperMousewheel, SwiperScrollbar]"
            :free-mode="true"
            slides-per-view="auto"
            :mousewheel="true"
            :scrollbar="{
              draggable: true,
            }"
            direction="vertical"
            :space-between="5"
            @swiper="setZoomSwiper"
          >
            <SwiperSlide v-for="image of mediaGallery" :key="image.media.url" class="mx-auto max-w-[1000px]">
              <div class="aspect-2/3">
                <SharedProxyImg
                  v-if="image?.media?.url"
                  :src="image.media.url"
                  :width="1000"
                  :height="1500"
                  :modifiers="{
                      width: 1000,
                      height: 1500,
                    }"
                  loading="eager"/>
              </div>
            </SwiperSlide>
          </Swiper>
        </nuxt-error-boundary>
      </div>
    </div>
  </Teleport>
</template>
